<template>
  <div class="container-fluid">
    <breadcrumb>
      <img
        src="static/img/survey.svg"
        class="breadcrumb-image"
      />
      <breadcrumb-item>
        <router-link to="/designeffectiveness">Select Entity</router-link>
      </breadcrumb-item>
      <breadcrumb-item active> Design Effectiveness </breadcrumb-item>
    </breadcrumb>

    <div class="row">
      <div class="col-md-3" style="min-width: 290px !important">
        <card v-if="loaded" class="header-card">
          <template slot="header">
            <h4 class="card-title">Design Effectiveness Score</h4>
            <!--<i class="fa fa-caret-down score-direction"></i>
            <span style="padding-left:20px">-13</span>-->
            <template v-if="model.score > qualitativeSetting.high">
              <p class="header-card-score" style="color: green">
                {{ model.score }}
              </p>
            </template>
            <template
              v-if="
                model.score > qualitativeSetting.medium &&
                model.score <= qualitativeSetting.high
              "
            >
              <p class="header-card-score" style="color: limegreen">
                {{ model.score }}
              </p>
            </template>
            <template
              v-if="
                model.score > qualitativeSetting.low &&
                model.score <= qualitativeSetting.medium
              "
            >
              <p class="header-card-score" style="color: goldenrod">
                {{ model.score }}
              </p>
            </template>
            <template
              v-if="
                model.score > qualitativeSetting.veryLow &&
                model.score <= qualitativeSetting.low
              "
            >
              <p class="header-card-score" style="color: darkorange">
                {{ model.score }}
              </p>
            </template>
            <template v-if="model.score <= qualitativeSetting.veryLow">
              <p class="header-card-score" style="color: red">
                {{ model.score }}
              </p>
            </template>
          </template>
          <template slot="footer">
            <div class="stats">
              <i class="fa fa-history"></i>
              Updated {{ model.date }}
            </div>
          </template>
        </card>
      </div>

      <div class="col-md-9">
        <card v-if="loaded" class="header-card">
          <template slot="header">
            <p class="card-category">
              Last 12 {{ model.controlFramework }} Design Effectiveness Assessment Performance
            </p>
          </template>
          <highcharts class="chart" :options="lineChart"></highcharts>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <card v-if="loaded">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <button
                style="height: 40px"
                type="button"
                @click="
                  $router.push(
                    `/designeffectiveness/${$route.params.organisationId}/deassessments/new`
                  )
                "
                class="btn btn-fill btn-info btn-wd"
              >
                <span class="btn-label">
                  <i class="fa fa-plus"></i>
                </span>
                New Design Effectiveness Assessment
              </button>
              <fg-input>
                  <el-date-picker
                    v-model="searchDate"
                    format="dd-MMM-yyyy"
                    type="daterange"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    :picker-options="pickerOptions"
                    v-on:input="getAll"
                  ></el-date-picker>
                </fg-input>
              <div class="mb-3">
                <el-select
                  name="control framework"
                  class="select-default"
                  ref="selection"
                  v-on:input="ddlControlFrameworkChange"
                  v-model="controlFrameworkSearchId"
                  placeholder="Select Control Framework"
                >
                  <el-option
                    class="select-default"
                    v-for="item in controlFrameworks"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>

                

                <!--<fg-input
                  type="search"
                  style="
                    width: 200px;
                    display: inline-block;
                    padding-left: 25px;
                  "
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                  v-on:input="getAll"
                />-->
              </div>
            </div>
            <div class="col-sm-12">
              <el-table
                v-loading="loading"
                style="width: 100%"
                :data="this.designEffectiveness"
              >
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                ></el-table-column>
                <el-table-column :min-width="70" fixed="right" label="Status">
                  <template slot-scope="props">
                    <i
                      v-if="props.row.isActive === 'True'"
                      class="fa fa-check status-active"
                    ></i>
                    <i v-else class="fa fa-times status-inactive"></i>
                  </template>
                </el-table-column>
                <el-table-column
                  :min-width="150"
                  fixed="right"
                  label="Progress"
                >
                  <template slot-scope="props">
                    <template v-if="props.row.isComplete === 'True'">
                      <l-progress
                        :value="props.row.percentageCompleted"
                        type="success"
                      ></l-progress>
                    </template>
                    <template v-else>
                      <l-progress
                        :value="props.row.percentageCompleted"
                        type="info"
                      ></l-progress>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  :min-width="120"
                  :width="100"
                  fixed="right"
                  label="Actions"
                >
                  <template slot-scope="props">
                    <a
                      v-tooltip.top-center="'Assessment'"
                      class="btn-info btn-simple btn-link"
                      @click="
                        $router.push(
                          `/designeffectiveness/${$route.params.organisationId}/deassessments/${props.row.id}/detail`
                        )
                      "
                    >
                      <i class="fa fa-diagnoses"></i>
                    </a>
                    <a
                      v-tooltip.top-center="'Edit'"
                      class="btn-info btn-simple btn-link"
                      @click="
                        $router.push(
                          `/designeffectiveness/${$route.params.organisationId}/deassessments/${props.row.id}/edit`
                        )
                      "
                    >
                      <i class="fa fa-edit"></i>
                    </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <div class>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
              v-on:input="getAll"
            ></l-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import Highcharts, { dateFormat } from "highcharts";
import More from "highcharts/highcharts-more";
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  TimeSelect,
} from "element-ui";
import { Breadcrumb, BreadcrumbItem } from "src/components/index";
import {
  Pagination as LPagination,
  Progress as LProgress,
} from "src/components/index";
import $ from "jquery";
import { std, mean } from "mathjs";

More(Highcharts);
var designEffectivenessLastTwelve = [];

export default {
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LProgress,
    Breadcrumb,
    BreadcrumbItem,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
  },
  computed: {
    pagedData() {
      let self = this;
      return self.designEffectiveness.slice(self.from, self.to);
    },
    to() {
      let self = this;
      let highBound = self.from + self.pagination.perPage;
      if (self.total < highBound) {
        highBound = self.total;
      }
      return highBound;
    },
    from() {
      let self = this;
      return self.pagination.perPage * (self.pagination.currentPage - 1);
    },
    total() {
      let self = this;
      return self.pagination.total;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      tableColumns: [
        {
          prop: "date",
          label: "Date Created",
          minWidth: 150,
        },
        {
          prop: "controlFramework",
          label: "Control Framework",
          minWidth: 150,
        },
        {
          prop: "score",
          label: "Score",
          minWidth: 60,
        },
      ],
      designEffectiveness: [],
      loading: true,
      loaded: false,
      controlFrameworkSearchId: null,
      controlFrameworks: [],
      searchDate: "",
      searchDateString: "",
      qualitativeSetting: {
        id: 0,
        name: "",
        veryHigh: 0,
        high: 0,
        medium: 0,
        low: 0,
        veryLow: 0,
      },
      model: {
        id: 0,
        controlFrameworkId: 0,
        description: "",
        score: "",
        controlFramework: "",
        date: "",
      },
      lineChart: {
        chart: {
          type: "spline",
          //type: "line",
          height: 245,
        },
        title: {
          text: "",
        },
        xAxis: {
          max: 12,
          min: 0,
        },
        yAxis: {
          title: {
            text: "Score",
          },
          max: 100,
          min: 0,
          plotLines: [
            {
              color: "#527055",
              width: 2,
              value: 0,
              dashStyle: "longdashdot",
            },
            {
              color: "#ba3f50",
              width: 2,
              value: 0,
              dashStyle: "longdashdot",
            },
            {
              color: "#ba3f50",
              width: 2,
              value: 0,
              dashStyle: "longdashdot",
            },
          ],
        },
        tooltip: {
          crosshairs: true,
          shared: true,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          spline: {
            marker: {
              radius: 4,
              lineColor: "#666666",
              lineWidth: 1,
            },
          },
          series: {
            tooltip: {
              headerFormat: "",
              pointFormat:
                "<span style='color:{point.color}'>●</span> {series.name}: <b>{point.y}, </b>Date: <b>{point.date}</b>",
            },
            cursor: "pointer",
            /*point: {
              events: {
                click: ({ point }) => {
                  this.get(point.id);
                }
              }
            }*/
          },
        },
        series: [
          {
            name: "Design Effectiveness Score",
            data: [],
            //color: "grey",
            //pointPadding: 0.1
          },
        ],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },

  created() {
    let self = this;
    //self.getAll();
    self.getLastTwelve(self.model.controlFrameworkId);
    self.getAllActiveControlFrameworks();
  },

  methods: {
    getAll() {
      let self = this;



      if (self.searchDate !== null) {
        if (self.searchDate.length !== 0) {
          let startDate = new Date(self.searchDate[0]);
          let endDate = new Date(self.searchDate[1]);
          self.searchDateString =
            startDate.getFullYear() +
            "-" +
            (startDate.getMonth() + 1) +
            "-" +
            startDate.getDate() +
            "," +
            endDate.getFullYear() +
            "-" +
            (endDate.getMonth() + 1) +
            "-" +
            endDate.getDate();
        }
      } else {
        self.searchDateString = "";
      }





      self.$store.state.services.dEControlAssessmentService
        .getAll({
          headers: {
            Pagination: `${self.pagination.currentPage},${self.pagination.perPage}`,
            organisationId: self.$route.params.organisationId,
          },
          params: {
            Filter: `${self.searchQuery}`,
            ControlFrameworkId: self.controlFrameworkSearchId,
            DateFilter: `${self.searchDateString}`
          },
        })
        .then((r) => {
          self.designEffectiveness = r.data;
          self.headerValues = JSON.parse(r.headers.pagination);
          self.pagination.total = self.headerValues.TotalItems;
          self.pagination.currentPage = self.headerValues.CurrentPage;
          self.pagination.perPage = self.headerValues.ItemsPerPage;

          self.loading = false;
          self.loaded = true;
        })
        .catch((err) => console.log(err));
    },

    getLastTwelve(controlFrameworkId) {
      let self = this;

      self.getQualitativeSetting();

      self.$store.state.services.dEControlAssessmentService
        .getAll({
          headers: {
            organisationId: self.$route.params.organisationId,
          },
          params: {
            ControlFrameworkId: controlFrameworkId,
            LastTwelve: "true",
          },
        })
        .then((r) => {
          if (r.data.length > 0) {
            var getLastAssessment = r.data.length - 1;
            self.model.id = r.data[getLastAssessment].id;
            self.model.controlFramework =
              r.data[getLastAssessment].controlFramework;
            self.model.description = r.data[getLastAssessment].description;
            self.model.controlFrameworkId =
              r.data[getLastAssessment].controlFrameworkId;
            self.model.score = r.data[getLastAssessment].score;
            self.model.date = r.data[getLastAssessment].date;

            self.controlFrameworkSearchId =
              r.data[getLastAssessment].controlFrameworkId;

            designEffectivenessLastTwelve = [];

            var controlLimitData = [];
            for (var i = 0; i < r.data.length; i++) {
              designEffectivenessLastTwelve.push({
                name: r.data[i].name,
                y: r.data[i].score,
                id: r.data[i].id,
                date: r.data[i].date,
              });
              controlLimitData.push(r.data[i].score);
            }
            self.lineChart.series[0].data = designEffectivenessLastTwelve;
            self.lineChart.yAxis.plotLines[0].value = Math.round(
              mean(controlLimitData)
            );
            self.lineChart.yAxis.plotLines[1].value = Math.round(
              mean(controlLimitData) + 2 * std(controlLimitData)
            );
            self.lineChart.yAxis.plotLines[2].value = Math.round(
              mean(controlLimitData) - 2 * std(controlLimitData)
            );
          } else {
            designEffectivenessLastTwelve = [];

            self.model.id = 0;
            self.model.controlFramework = "";
            self.model.description = "";
            self.model.controlFrameworkId = 0;
            self.model.score = "";
            self.model.date = "";
            self.lineChart.series[0].data = designEffectivenessLastTwelve;
          }
          self.getAll();
        })
        .catch((err) => console.log(err));
    },
    getAllActiveControlFrameworks() {
      let self = this;
      self.$store.state.services.controlFrameworkService
        .getAll({
          params: {
            ActiveControlFrameworks: true,
          },
        })
        .then((r) => {
          self.controlFrameworks = r.data;
        })
        .catch((err) => console.log(err));
    },
    ddlControlFrameworkChange(value) {
      let self = this;
      self.getLastTwelve(value);
    },

    getQualitativeSetting() {
      let self = this;
      self.$store.state.services.appetiteService
        //3 = Control Assessment
        .get(3)
        .then((r) => {
          self.qualitativeSetting.id = r.data.id;
          self.qualitativeSetting.name = r.data.name;
          self.qualitativeSetting.veryHigh = r.data.veryHigh;
          self.qualitativeSetting.high = r.data.high;
          self.qualitativeSetting.medium = r.data.medium;
          self.qualitativeSetting.low = r.data.low;
          self.qualitativeSetting.veryLow = r.data.veryLow;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style>
</style>


